<template>
  <!--
    The view for all Software Components Login Data
  -->
  <div class="installationDetail_LoginData">
    <template>
      <div class="card">
        <Portlet
          title="Administration"
          icon="key"
        >
          <template slot="buttons">
            <button 
              v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin') || authenticationHasRole('firstlevel'))"
              class="btn btn-sm btn-primary float-right mt-2 mb-2" 
              @click="showSidebarAdd"
            >
              <font-awesome-icon
                class="mr-1"
                icon="plus"
              />
              <span>{{ $t('add') }}</span>
            </button>
          </template>
          <button 
            class="btn btn-sm btn-primary mb-2"
            @click="getVersions()" 
          >
            <span>Show Software Versions</span>
          </button>
          <a
            v-if="contentfulVal"
            :href="`https://app.contentful.com/spaces/${contentfulVal.space}/environments/${contentfulVal.env}`"
            class="btn btn-sm btn-primary ml-2 mb-2"
            target="_blank"
          >Contentful</a>
          <InstallationLoginDataList
            ref="installationLoginDataList"
            :installation-id="installationId"
            @reloadAuditLogs="reloadAuditLogs"
          />

          <SoftwareComponentSmallList
            ref="softwareComponentSmallList"
            class="mt-4"
            :installation-id="installationId"
            @contentfulBtn="contentfulBtn"
          />
          <LoadingPlaceholder v-if="subLoading" />
          <JiraSubtasks
            v-else-if="jiraSubtasks"
            class="mt-4"
            :installation-id="installationId"
            :title="'Worklog Subtasks'"
            :jira-subtasks="jiraSubtasks"
          />
          <DatabaseAuditLogList
            v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
            ref="databaseAuditLogList"
            :is-installation-login="true"
            :installation-id="installationId"
            @reload="reloadLoginList"
          />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationDetailLoginData",
  components: {
    InstallationLoginDataList : () => import('@/components/Installation/InstallationLoginDataList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    SoftwareComponentSmallList: () => import('@/components/SoftwareComponent/SoftwareComponentSmallList.vue'),
    JiraSubtasks: () => import('@/components/Jira/JiraSubtasks.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: 'Administration'
    }
  },
  data () {
    return {
      contentfulVal: null,
      jiraSubtasks: null,
      subLoading: false
    }
  },
  created () {
    this.getSubtasks();
  },
  methods: {
    getVersions () {
       let routeData = this.$router.resolve({path: `/software-components/n/${this.installationId}`});
        window.open(routeData.href, '_blank');
    },
    contentfulBtn (val) {
      this.contentfulVal = val;
    },
    showSidebarAdd () {
      this.$refs.installationLoginDataList.showAddLoginData();
    },
    reloadLoginList () {
      this.$refs.installationLoginDataList.getInstallationLoginData();
    },
    reloadAuditLogs () {
       if(this.$refs.databaseAuditLogList) {
         this.$refs.databaseAuditLogList.reloadAuditLogs();
       }
    },
    getSubtasks () {
      this.subLoading = true;
      this.axios.get(`/Issue/GetWorklogOpenSubtasks?issueId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.jiraSubtasks = response.data;
        })
        .finally(() => {
          this.subLoading = false;
        });
    },
  }
}
</script>